const customMediaQuery = (width: number, bound = "min") =>
    `@media (${bound}-width: ${width}px)`

export const sizes = {
    lg: 984,
    md: 744,
    sm: 320,
    xl: 1440,
}

export const media = {
    lgUp: customMediaQuery(sizes.lg),
    mdDown: customMediaQuery(sizes.md, "max"),
    mdUp: customMediaQuery(sizes.md),
    smUp: customMediaQuery(sizes.sm),
    xlDown: customMediaQuery(sizes.xl, "max"),
    xlUp: customMediaQuery(sizes.xl),
}
