import { shouldPolyfill as shouldPolyfillDateTimeFormat } from "@formatjs/intl-datetimeformat/should-polyfill"
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill"
import { shouldPolyfill as shouldPolyfillNumberFormat } from "@formatjs/intl-numberformat/should-polyfill"
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill"
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from "@formatjs/intl-relativetimeformat/should-polyfill"

const safeShouldPolyfill = (shouldPolyfillTest) => {
    let shouldPolyfill = false

    try {
        shouldPolyfill = shouldPolyfillTest()
    } catch (e) {
        shouldPolyfill = true
    }

    return shouldPolyfill
}

/**
 * Dynamically polyfill Intl API & its locale data
 * @param locale locale to polyfill
 */
export const polyfill = async (locale: string = "") => {
    const dataPolyfills = []
    // Polyfill Intl.getCanonicalLocales if necessary
    if (shouldPolyfillGetCanonicalLocales()) {
        await import(
            /* webpackChunkName: "intl-getcanonicallocales" */ "@formatjs/intl-getcanonicallocales/polyfill"
        )
    }

    // Polyfill Intl.PluralRules if necessary
    if (safeShouldPolyfill(shouldPolyfillPluralRules)) {
        await import(
            /* webpackChunkName: "intl-pluralrules" */ "@formatjs/intl-pluralrules/polyfill"
        )
    }

    if ((Intl.PluralRules as any).polyfilled) {
        switch (locale) {
            case "nl":
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-pluralrules" */ "@formatjs/intl-pluralrules/locale-data/nl"
                    )
                )
                break
            default:
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-pluralrules" */ "@formatjs/intl-pluralrules/locale-data/en"
                    )
                )
                break
        }
    }

    // Polyfill Intl.NumberFormat if necessary
    if (safeShouldPolyfill(shouldPolyfillNumberFormat)) {
        await import(
            /* webpackChunkName: "intl-numberformat" */ "@formatjs/intl-numberformat/polyfill"
        )
    }

    if ((Intl.NumberFormat as any).polyfilled) {
        switch (locale) {
            case "nl":
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-numberformat" */ "@formatjs/intl-numberformat/locale-data/nl"
                    )
                )
                break
            default:
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-numberformat" */ "@formatjs/intl-numberformat/locale-data/en"
                    )
                )
                break
        }
    }

    // Polyfill Intl.DateTimeFormat if necessary
    if (safeShouldPolyfill(shouldPolyfillDateTimeFormat)) {
        await import(
            /* webpackChunkName: "intl-datetimeformat" */ "@formatjs/intl-datetimeformat/polyfill"
        )
    }

    if ((Intl.DateTimeFormat as any).polyfilled) {
        dataPolyfills.push(import("@formatjs/intl-datetimeformat/add-all-tz"))
        switch (locale) {
            case "nl":
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-datetimeformat" */ "@formatjs/intl-datetimeformat/locale-data/nl"
                    )
                )
                break
            default:
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-datetimeformat" */ "@formatjs/intl-datetimeformat/locale-data/en"
                    )
                )
                break
        }
    }

    // Polyfill Intl.RelativeTimeFormat if necessary
    if (safeShouldPolyfill(shouldPolyfillRelativeTimeFormat)) {
        await import(
            /* webpackChunkName: "intl-relativetimeformat" */ "@formatjs/intl-relativetimeformat/polyfill"
        )
    }

    if ((Intl as any).RelativeTimeFormat.polyfilled) {
        switch (locale) {
            case "nl":
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-relativetimeformat" */ "@formatjs/intl-relativetimeformat/locale-data/nl"
                    )
                )
                break
            default:
                dataPolyfills.push(
                    import(
                        /* webpackChunkName: "intl-relativetimeformat" */ "@formatjs/intl-relativetimeformat/locale-data/en"
                    )
                )
                break
        }
    }

    await Promise.all(dataPolyfills)
}
