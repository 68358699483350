export const getLinkHref = (content) => {
    if (content?.button_link?.linktype === "story") {
        if (content?.button_link?.cached_url.startsWith("/pl/")) {
            return content?.button_link?.cached_url
        }
        return `/${content?.button_link?.cached_url}`
    } else if (content?.button_link?.linktype === "email") {
        return `mailto:${content?.button_link?.email}`
    }
    return content?.button_link?.url
}
