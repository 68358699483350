import { staticPropsWithSdk } from "./graphqlClient"
import { loadMessages } from "./loadMessages"

export const enhancedStaticProps: typeof staticPropsWithSdk = (
    getStaticProps
) =>
    staticPropsWithSdk(async (context) => {
        const { defaultLocale, locale, sdk } = context

        const [
            { FooterItem: footer },
            { NavigationItem: navigation },
            staticProps,
            messages,
        ] = await Promise.all([
            sdk.footerItem({
                slug: `${defaultLocale !== locale ? `${locale}/` : "/"}footer`,
            }),
            sdk.navigationItem({
                slug: `${
                    defaultLocale !== locale ? `${locale}/` : "/"
                }navigation`,
            }),
            getStaticProps(context),
            loadMessages(context),
        ])

        return {
            ...staticProps,
            props: {
                ...(staticProps as any).props,
                footer,
                navigation,
                messages,
            },
        }
    })
