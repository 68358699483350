import { withPasswordProtect } from "@storyofams/next-password-protect"
import { DefaultSeo } from "next-seo"
import { AppProps } from "next/app"
import { useRouter } from "next/router"

import { Providers } from "~components/common/Providers"
import { seo } from "~config"
import { initSentry } from "~lib"
import CSSreset from "~styles/CSSreset"

import "../../public/static/fonts/stylesheet.css"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    initSentry()
}

const MyApp = ({ Component, pageProps }: AppProps) => {
    const { locale } = useRouter()

    return (
        <Providers pageProps={pageProps} locale={locale}>
            <CSSreset />
            <DefaultSeo {...seo} />
            <Component {...pageProps} />
        </Providers>
    )
}

export default process.env.PASSWORD_PROTECT ? withPasswordProtect(MyApp) : MyApp
