import { ReactNode } from "react"
import { StoryProvider } from "@storyofams/storyblok-toolkit"
import { LazyMotion } from "framer-motion"
import { IntlProvider } from "react-intl"
import { ThemeProvider } from "styled-components"

import { CursorProvider } from "~/components/common/Cursor"

import theme from "~styles/theme"
import { MediaQueryProvider } from "~lib/useMediaQuery"

const loadFeatures = () => import("./motionFeatures").then((res) => res.default)

type ProviderProps = {
    children: ReactNode
    /** defaults to empty object */
    pageProps?: any
    /** defaults to 'en' as per the defaultLocale */
    locale?: string
}

export const Providers = ({
    children,
    locale = "en",
    pageProps = {},
}: ProviderProps) => {
    return (
        <IntlProvider
            messages={pageProps.messages}
            defaultLocale="en"
            locale={locale}
        >
            <ThemeProvider theme={theme}>
                <MediaQueryProvider>
                    <StoryProvider>
                        <LazyMotion features={loadFeatures}>
                            <CursorProvider>{children}</CursorProvider>
                        </LazyMotion>
                    </StoryProvider>
                </MediaQueryProvider>
            </ThemeProvider>
        </IntlProvider>
    )
}
