const space = {
    0: 0,
    0.25: 2,
    0.5: 4,
    0.75: 6,
    1: 8,
    1.25: 10,
    1.5: 12,
    1.75: 14,
    2: 16,
    2.25: 18,
    2.5: 20,
    3: 24,
    4: 32,
    5: 40,
    6: 48,
    7: 56,
    8: 64,
    9: 72,
    10: 80,
    12: 96,
    15: 120,
    20: 160,
}

export default space
