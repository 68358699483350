import { Breakpoints } from "../styled"
import space from "./space"

const theme = {
    colors: {
        transparent: "rgba(255, 255, 255, 0);",
        white: "#ffffff",
        banana: "#F4F5E2",
        banana600: "#DADBCA",
        cashew: "#EDD279",
        cashew600: "#D4BC6C",
        tiger: "#DE773C",
        tiger600: "#C46A35",
        sangria: "#8F201A",
        sangria600: "#751C17",
        fig: "#795CB9",
        fig600: "#6A50A1",
        lapiz: "#01A19A",
        aloe: "#84AC4A",
        aloe600: "#70913F",
        black: "#34393E",
        grey: "#a49561",
    },
    fontWeights: {
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 900,
    },
    fonts: {
        heading: `'Nuvo pro', serif`,
        body: `'Bitter', serif`,
    },
    fontSizes: {
        1.25: space["1.25"],
        1.5: space["1.5"],
        1.75: space["1.75"],
        2: space["2"],
        2.25: space["2.25"],
        2.5: space["2.5"],
        3: space["3"],
        4: space["4"],
        5: space["5"],
        6: space["6"],
        7: space["7"],
        8: space["8"],
        10: space["9"],
        20: space["20"],
        root: space["1.75"],
        heading: space["4"],
    },
    lineHeights: {
        normal: 1,
        heading: 1.1,
        medium: 1.25,
        high: 1.6,
    },
    space: {
        ...space,
    },
    sizes: {
        maxWidth: 1260,
    },
    breakpoints: ["768px", "992px", "1024px", "1280px"] as Breakpoints,
    zIndices: {
        hide: -1,
        base: 0,
        docked: 10,
        dropdown: 1000,
        sticky: 1100,
        banner: 1200,
        overlay: 1300,
        modal: 1400,
        popover: 1500,
        skipLink: 1600,
        toast: 1700,
        tooltip: 1800,
    },
    radii: {
        none: "0",
        xs: "4px",
        sm: "6px",
        md: "8px",
        lg: "16px",
        full: "9999px",
    },
    borders: {
        none: 0,
        "1px": "1px solid",
        "2px": "2px solid",
        "4px": "4px solid",
    },
    shadows: {
        sm: "0px 2px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)",
        md: "0px 2px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)",
        lg: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1)",
        none: "none",
    },
}

theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]
theme.breakpoints.lg = theme.breakpoints[2]
theme.breakpoints.xl = theme.breakpoints[3]

theme.breakpoints.maxXs = "767px"
theme.breakpoints.maxSm = "991px"
// theme.breakpoints.maxMd = '1023px';
// theme.breakpoints.maxLg = '1279px';

export default theme
