const siteTitle = "Honest Cashew"

const defaultSeo = {
    openGraph: {
        type: "website",
        locale: "en_IE",
        url: "/",
        site_name: siteTitle,
    },
    twitter: {
        handle: "@HonestCashew",
        cardType: "summary_large_image",
    },
    titleTemplate: `%s`,
}

if (process.env.NODE_ENV === "development") {
    defaultSeo.titleTemplate = `DEV: %s`
}

export default defaultSeo
