import React, { createContext, useContext, useMemo } from "react"
import useMedia from "use-media"

import { sizes } from "constants/media"

export interface MediaQueryProviderProps {
    /** Pass any React.Node to render inside this component */
    children?: React.ReactNode
}

const mediaQueries = {
    desktop: `(min-width: ${sizes.lg}px)`,
    desktopOnly: `(min-width: ${sizes.lg}px) and (max-width: ${
        sizes.xl - 1
    }px)`,
    mobile: `(max-width: ${sizes.md - 1}px)`,
    tablet: `(min-width: ${sizes.md}px) and (max-width: ${sizes.lg - 1}px)`,
    tabletAndLarger: `(min-width: ${sizes.md}px)`,
    xlDesktop: `(min-width: ${sizes.xl}px)`,
}

function getContainerSize(
    isMobileView: boolean,
    isTabletView: boolean,
    isDesktopView: boolean,
    isDesktopXLView: boolean
): number {
    let containerSize: number = sizes.sm
    const queryResults: Array<{ [name: string]: boolean }> = [
        { sm: isMobileView },
        { md: isTabletView },
        { lg: isDesktopView },
        { xl: isDesktopXLView },
    ]

    const activeQuery = queryResults.find((query) => {
        return Object.values(query)[0]
    })

    if (activeQuery) {
        containerSize = sizes[Object.keys(activeQuery)[0]]
    }

    return containerSize
}

interface MediaQueryProps {
    isXlDesktopView: boolean
    isMobileView: boolean
    isDesktopView: boolean
    isTabletView: boolean
    isTabletViewOrLarger: boolean
    containerSize: number
}

const MediaQueryContext = createContext({} as MediaQueryProps)

export function useMediaQueryContext(): MediaQueryProps {
    return useContext(MediaQueryContext)
}

export function MediaQueryProvider({
    children,
}: MediaQueryProviderProps): JSX.Element {
    {
        const isMobileView = useMedia(mediaQueries.mobile)
        const isTabletView = useMedia(mediaQueries.tablet)
        const isTabletViewOrLarger = useMedia(mediaQueries.tabletAndLarger)
        const isDesktopView = useMedia(mediaQueries.desktop)
        const isDesktopOnlyView = useMedia(mediaQueries.desktopOnly)
        const isXlDesktopView = useMedia(mediaQueries.xlDesktop)
        const containerSize = getContainerSize(
            isMobileView,
            isTabletView,
            isDesktopOnlyView,
            isXlDesktopView
        )

        const value = useMemo(
            () => ({
                containerSize,
                isDesktopView,
                isMobileView,
                isTabletView,
                isTabletViewOrLarger,
                isXlDesktopView,
            }),
            [
                containerSize,
                isMobileView,
                isDesktopView,
                isTabletView,
                isTabletViewOrLarger,
                isXlDesktopView,
            ]
        )

        return (
            <MediaQueryContext.Provider value={value}>
                {children}
            </MediaQueryContext.Provider>
        )
    }
}
