import { polyfill } from "./polyfills"

export const loadMessages = async (context) => {
    const { locale } = context
    let messagePromise

    switch (locale) {
        case "pl":
            messagePromise =
                process.env.NODE_ENV !== "production"
                    ? import("../translations/pl.json")
                    : import("../../compiled-translations/pl.json")
            break
        default:
            messagePromise =
                process.env.NODE_ENV !== "production"
                    ? import("../translations/en.json")
                    : import("../../compiled-translations/en.json")
            break
    }

    const [, messages] = await Promise.all([polyfill(locale), messagePromise])

    return messages.default
}
