export const getNavLinkHref = (hyperlink) => {
    if (hyperlink?.url) {
        return hyperlink.url
    }
    if (hyperlink?.cached_url === "home") {
        return "/"
    }
    if (hyperlink?.cached_url.startsWith("/pl/")) {
        return hyperlink?.cached_url
    }

    return `/${hyperlink?.cached_url}`
}
